
import React from 'react'

import './Assets/Style/style.css'
import { Home } from './Screens'

export const App = () => {
  return (
    <div className='main' >
      <Home />
    </div>
  )
}