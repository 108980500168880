import React from 'react'

import { Footer, Header, Main } from '../Components'

export const Home = () => {
  return (
    <>
        <Header />
        <Main />  
        <Footer />
    </>
  )
}