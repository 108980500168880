import React from 'react'

import '../Assets/Style/Main.css'

export const BtnSocialMedia = ({link, name}) => {
  return (
    <div
        className='btnSocialMedia'
    >
      <a
        href={link}
        target='_blank'
        className='btn-link'
      >
        <i
            className={name}
            aria-hidden="true"
        ></i>
      </a>
    </div>
  )
}