import React, { useRef, useState } from "react";
import ReactAudioPlayer from "react-audio-player";

import '../Assets/Style/Reproductor.css'

import IconoApp from '../Assets/Image/IconoApp.jpg'

export const Reproductor = () => {

    const [iconPlayBtn] = useState('fa-play');
    const [iconPauseBtn] = useState('fa-pause');
    const [btnPlayPause, setBtnPlayPause] = useState(true);
  
    const audioStream = useRef(null);
  
    const [urlStream] = useState('https://cast6.my-control-panel.com/proxy/soyfm/stream');
    const [nameRadio] = useState('Radio Soy Fm');
    const [sloganRadio] = useState('Una radio para jóvenes');
  
    const handlePlayBtn = () => {
  
      setBtnPlayPause(!btnPlayPause)
  
      if(btnPlayPause){
        audioStream.current.audioEl.current.src = urlStream;
        audioStream.current.audioEl.current.play();
      }else{
        audioStream.current.audioEl.current.pause();
        audioStream.current.audioEl.current.src = '';
  
      }
      
    }

    return(
        <>
        <ReactAudioPlayer 
            controls={false}
            src={urlStream}
            onPlay={() => setBtnPlayPause(false)}
            onPause={() => setBtnPlayPause(true)}
            ref={audioStream}
        />
            <div  
                className="Reproductor"
            >

                    <div
                        className="content-info-radio"
                    >
                        <div 
                            className="content-image-music"
                        >
                            <img src={IconoApp} alt="Soy Fm" className="image-music" />
                        </div>

                        <div
                            className="content-info-music"
                        >
                            <h2> {nameRadio} </h2>
                            <p > {sloganRadio} </p>
                        </div>

                    </div>
                <div 
                    onClick={handlePlayBtn}
                    className="content-controles"
                >
                    <i className={`fas ${btnPlayPause ? iconPlayBtn : iconPauseBtn}`}></i>

                </div>

            </div>
        </>
    )

}