import React from 'react'

import '../Assets/Style/Menu.css'

export const Link = ({name, link, target}) => {
  return (
    <div
        className='itemLink'
    >
        <a
            href={link}
            target={target}
        >
            <p> {name} </p>
        </a>
    </div>
  )
}