import React from 'react'
import '../Assets/Style/Footer.css'
import { base_url } from '../Utils/Constants'

export const Footer = () => {
  return (
    <div
      className='Footer'
    >
      <p >© <a href={base_url} >Radio Soy Fm</a> 2023 Todos los derechos reservados</p>
    </div>
  )
}