import React from 'react'

import '../Assets/Style/style.css'
import '../Assets/Style/Main.css'
import { Reproductor } from './Reproductor'

import Slogan from '../Assets/Image/slogan.png'
import Logo from '../Assets/Image/LogoShadow.png'
import { BtnSocialMedia } from './BtnSocialMedia'
 
export const Main = () => {
  return (
    <div
        className='container content-main'
    >
      <div
        className='content'
      >
        <div
          className='content-slogan-redes'
        >
          <div
            className='content-image-redes'
          >
            <img 
              src={Slogan}
            />
          </div>

          <div
            className='content-link-redes-sociales'
          >

            <BtnSocialMedia 
              link="https://www.facebook.com/radiosoyfm"
              name="fab fa-facebook-f"
            />
            <BtnSocialMedia
              link="https://www.instagram.com/radiosoyfm/"
              name="fab fa-instagram" 
            />
            <BtnSocialMedia 
              link="https://api.whatsapp.com/send?phone=992501515&text=Hola,%20me%20podr%C3%ADas%20complacer%20con%20la%20canci%C3%B3n%20"
              name="fab fa-whatsapp"
            />
            <BtnSocialMedia 
              link="https://www.tiktok.com/@radiosoyfm93.1"
              name="fab fa-tiktok"
            />

          </div>

        </div>
        <div
          className='content-image-main'
        >
          <img 
            src={Logo}
          />
        </div>
      </div>
      <Reproductor />
    </div>
  )
}