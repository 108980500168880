import React from 'react'

import '../Assets/Style/Menu.css'

import { Link } from './Link'
import { base_url } from '../Utils/Constants'
import codigoEtica from '../Uploads/CodigoDeEtica.pdf'

export const Menu = ({showMenu,setShowMenu}) => {

    const email = 'mailto:radiosoyfm93.1@hotmail.com'

  return (
    <>
        <div
            className={`menu-mobil ${showMenu && 'show' } `}
        >
            <div
                onClick={()=>setShowMenu(false)}
                className='hidden-menu'
            ></div>
            <div
                className='content-menu'
            >
                <Link 
                    name="Home"
                    link={base_url}
                    target="_self"
                />
                <Link 
                    name="Código de ética"
                    link={codigoEtica}
                    target="_blank"
                />
                <Link 
                    name="Buzón ciudadano"
                    link={email}
                    target="_blank"
                />
            </div>
        </div>


    </>
  )
}