import React, { useState } from 'react'

import '../Assets/Style/style.css'
import '../Assets/Style/Header.css'

import Logo from '../Assets/Image/LogoSoyFm.png'
import { Menu } from './Menu'
import { Link } from './Link'

import { base_url } from '../Utils/Constants'
import codigoEtica from '../Uploads/CodigoDeEtica.pdf'

export const Header = () => {

  const [showMenu, setShowMenu] = useState(false)

  const handleMenu = () => {
    setShowMenu(true)
  }

  const email = 'mailto:radiosoyfm93.1@hotmail.com'

  return (
    <div
        className='Header'
    >
      <div className='container content-header'>

        <div
          className='Logo'
        >
          <img 
            src={Logo}
            className='img-logo'
          />
        </div>

        <div
          className='btn-menu'
          onClick={handleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div
                className='menu-desktop'
            >
                <div
                    className='content-menu-desktop'
                >
                    <Link 
                        name="Home"
                        link={base_url}
                        target="_self"
                    />
                    <Link 
                        name="Código de ética"
                        link={codigoEtica}
                        target="_blank"
                    />
                    <Link 
                      name="Buzón ciudadano"
                      link={email}
                      target="_blank"
                  />
                </div>
        </div>

      </div>
        <Menu 
          setShowMenu={setShowMenu}
          showMenu={showMenu}
        />
    </div>
  )
}